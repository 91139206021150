import * as React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { default as SbsIHCB } from "../components/sections/SidebysideImageHeadingContentButton"
import WorkHeader from "../components/sections/WorkHeader"
import CallToAction from "../components/sections/CallToAction"
import SimilarWorks from "../components/SimilarWorks"
// import get from 'lodash/get'
// import isEmpty from 'lodash/isEmpty'
//const shortcodes = { WorkHeader, SbsIHCB, StaticImage, DynamicImage }
export default function WorkPage({ data, ...props }) {
  const { title, slug, description, bannerImage, categories, headers, stats } =
    data.mdx
  return (
    <Layout>
      <SEO
        title={`${title} | ${categories} Work | Majiska`}
        description={description}
      />

      {headers.map(
        (header, i) =>
          (i === 0 && (
            <WorkHeader
              title={title}
              content={header.content.childMdx.rawBody}
              banner={bannerImage}
              image={header.image.gatsbyImageData}
              categories={categories}
              stats={stats}
              isImageSquare
            />
          )) ||
          (i < 3 && (
            <SbsIHCB
              isEven={i % 2 !== 0}
              isFull={i === 3}
              heading={header && header.title}
              content={header && header.content.childMdx.rawBody}
              image={
                <GatsbyImage image={header.image.gatsbyImageData} alt="" />
              }
              padding="py-0"
              isImageSquare={true}
            />
          )) ||
          (i === 3 && (
            <SbsIHCB
              isFull={true}
              heading={header && header.title}
              content={header && header.content.childMdx.rawBody}
              image={
                <GatsbyImage image={header.image.gatsbyImageData} alt="" />
              }
              imagePosition="top"
            />
          ))
      )}

      <CallToAction />
      <div className="container--padded pb-resp-16-32 pt-resp-16-32">
        <h3 className="text-center text-3xl pb-resp-12-20">More works</h3>
        <SimilarWorks categories={categories} currentPostSlug={slug} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    mdx: contentfulPastWorks(id: { eq: $id }) {
      title
      slug
      createdAt(formatString: "MMMM DD, YYYY")
      description
      categories
      markAsFeatured
      stats {
        number
        description
      }
      bannerImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      headers {
        title
        isHero
        id
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        content {
          childMdx {
            rawBody
            excerpt(pruneLength: 500)
          }
        }
      }
    }
  }
`
