import * as React from 'react'
import { Link } from 'gatsby'

export interface IPadding {
  padding?: "py-0" | "py-resp-12-20" | "py-resp-6-14" | "py-resp-16-24" | "py-resp-16-32" | "py-resp-16-32 lg:pt-0" | "py-resp-16-32 lg:pb-0" | "py-resp-16-32 lg:py-0" | "py-resp-16-24 lg:pt-0" | "py-resp-16-24 lg:pb-0" | "py-resp-16-24 lg:py-0" | "lg:py-resp-16-24 pt-0" | "lg:py-resp-16-24 pb-0" | "lg:py-resp-16-24 py-0"

}

interface SidebysideImageHeadingContentButtonProps extends IPadding {
  hasButton?: boolean
  buttonLabel?: string
  buttonLink?: string
  isEven?: boolean
  isBreakout?: boolean
  isFull?: boolean
  isImageSquare?: boolean
  heading?: React.ReactNode
  content?: React.ReactNode
  image?: React.ReactNode
  children?: React.ReactChildren
  className?: string
  imagePosition?: "top"
}
const SidebysideImageHeadingContentButton = ({
  isEven = false,
  className,
  hasButton,
  buttonLabel,
  buttonLink,
  isFull = false,
  isBreakout = false,
  heading,
  isImageSquare,
  content,
  image,
  children,
  padding,
  imagePosition
}: SidebysideImageHeadingContentButtonProps) => {
  return (
    <>
      <section
        className={`section--sbsihcb  ${padding ? padding : 'py-resp-16-24'}  ${className} ${isEven ? 'is-even' : 'is-odd'
          } ${isBreakout ? 'is-breakout' : ''} ${isFull ? 'is-full' : ''
          } overflow-hidden`}
      >
        <div className="container--padded">
          <div
            className={`flex--margined flex-col-reverse items-center  ${isEven ? 'lg:flex-row-reverse' : 'lg:flex-row'
              }`}
          >
            <div
              className={`relative z-10 w-full ${!isFull && 'lg:w-1/2'} ${isFull
                ? 'px-resp-6-14'
                : isEven
                  ? 'pl-resp-6-28 pr-resp-6-14'
                  : 'pr-resp-6-28 pl-resp-6-14'
                }`}
            >
              <div className={`${isFull && 'max-w-3xl mx-auto text-center'}`}>
                <h2
                  className={`heading  ${isFull && !image ? 'text-5xl' : 'text-4xl'
                    } mb-5 sm:mb-8`}
                >
                  {heading}
                </h2>
                <div className="content">{content}</div>
                {hasButton && (
                  <div className="buttons mt-6 lg:mt-12">
                    <Link
                      to={buttonLink}
                      className="btn btn--secondary btn--wide"
                    >
                      {buttonLabel}
                    </Link>
                  </div>
                )}
              </div>
            </div>
            {(image || children) && (
              <div
                className={`relative z-0  w-full ${isImageSquare ? '-mb-24' : 'mb-8'} lg:mb-0 ${isFull && (isEven ? 'pb-0 lg:pb-resp-12-20' : 'pt-0 lg:pt-resp-12-20')
                  } ${!isFull && 'lg:w-1/2'} ${isBreakout && ' h-resp-md-500px-800px '
                  }  ${isFull
                    ? 'px-resp-6-14'
                    : isEven
                      ? 'pr-6 lg:pr-0 pl-resp-6-14'
                      : 'pl-6 lg:pl-0 pr-resp-6-14'
                  }`}
              >
                <div
                  className={`inner   ${isBreakout &&
                    (isEven ? 'relative lg:absolute left-0' : 'relative lg:absolute right-0')
                    } `}
                >
                  <div
                    className={`image  image--center ${imagePosition ? imagePosition : ''} relative overflow-hidden  ${isBreakout
                      ? 'pt-75perc md:pt-0 h-resp-md-500px-800px lg:w-screen-1/2'
                      : isFull
                        ? 'pt-75perc lg:pt-33perc'
                        : isImageSquare ? 'pt-100perc' : 'pt-75perc'
                      }`}
                  >
                    {image || children}
                  </div>
                </div>
                {isImageSquare &&
                  <div className="absolute h-40 w-full bottom-0 left-0 lg:hidden from-transparent bg-gradient-to-b to-theme"></div>
                }
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default SidebysideImageHeadingContentButton
