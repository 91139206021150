import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'


export default function WorkHeader({ banner, image, title, categories, content, stats, isImageSquare }) {
    const gatsbyImgBanner = getImage(banner)
    const gatsbyImgContent = getImage(image)

    return (
        <section className="section--work-header relative overflow-hidden is--dimmed-gradient">

            <div className="image image--center z-10">
                <GatsbyImage image={gatsbyImgBanner} alt={`random`} />
            </div>

            <div className="container--padded pt-resp-24-56 pb-resp-16-48 relative z-30">
                <div className="flex--margined items-center">
                    <div className="w-full lg:w-1/2 padded">
                        <h1 className="text-h1 leading-none mb-6 lg:mb-10">{title}</h1>
                        {categories &&
                            <ul className="list-none items-center flex">
                                {categories.map((cat, i) => {
                                    return (
                                        <li key={i} className=" text-accent font-bold uppercase text-sm mr-4">{cat}</li>
                                    )
                                })}

                            </ul>
                        }
                        {content &&
                            <div className="content mt-5 lg:mt-8">
                                {content}
                            </div>
                        }
                        {stats &&
                            <div className="stats stats--work mt-8 md:pr-20">
                                {stats.map((stat, i) => {
                                    return (
                                        <li key={i} className="block--stat">
                                            <div className="stat__num">
                                                <span className="text">{stat.number}
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 150 83.87"
                                                    >
                                                        <defs></defs>
                                                        <g id="Layer_2" data-name="Layer 2">
                                                            <g id="Layer_1-2" data-name="Layer 1">
                                                                <path
                                                                    className="cls-1"
                                                                    d="M149.67,5.71l.23-1h0l0-.17h0a3.9,3.9,0,0,0-.51-2.73A3.81,3.81,0,0,0,145.59.05h0L109.75,6.18l1.58,9.24,19.32-3.3L93.19,49.58a11.63,11.63,0,0,0-8.53,0L67.06,32a11.7,11.7,0,1,0-21.78,0L16,61.28a11.69,11.69,0,1,0,6.61,6.6l29.3-29.29a11.73,11.73,0,0,0,8.53,0L78,56.19a11.7,11.7,0,1,0,21.78,0l36.85-36.86-4.48,18.35,9.14,2.22,8.15-33.38"
                                                                />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </span>

                                            </div>
                                            <p className="stat__text">{stat.description}</p>
                                        </li>
                                    )
                                })}
                            </div>
                        }
                    </div>
                    <div className="w-full lg:w-1/2 padded mt-6 lg:mt-0">
                        <div className={`relative overflow-hidden image image--center ${isImageSquare ? 'pt-100perc' : 'pt-66perc '}`}>
                            {<GatsbyImage image={gatsbyImgContent} alt="" />}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}